.user-chat {
    justify-content: end;
    padding: 10px 35px 10px 10px;
    margin: 10px 0;
    border-radius: 8px;
}
.user-side {
    justify-content: end;
    width: 90%;
    margin: 0 20px 0 auto;
    align-items: baseline;
}
.gpt-side {
    width: 90%;
    margin: 10px auto 10px 20px;
    position: relative;
    border-radius: 8px;
}
.right-body-mobile {
    position: relative;
    max-height: 85vh;
    border-radius: 16px;
    overflow-y: scroll;
    top: -50px;
}
.bg-chat {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #000000;
}
.ask-again {
    position: fixed;
    bottom: -5px;
    width: 92%;
}
.ask-again .ant-input {
    border-radius: 8px;
    background: #212121;
    border: none;
    height: 48px;
    font-size: 16px;
    color: white;
}
.ask-again .ant-input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

@media screen and (max-device-width: 480px) {
    .gpt-side {
        margin: 10px auto 10px 0px;
    }
    .user-chat {
        padding: 10px;
    }
}