.font-10 {
    font-size: 10px;
}
.font-14 {
    font-size: 14px;
}
.font-16 {
    font-size: 16px;
}
.font-18 {
    font-size: 18px;
}
.font-20 {
    font-size: 20px;
}
.font-24 {
    font-size: 24px;
}
.font-32 {
    font-size: 32px;
}
.font-45 {
    font-size: 45px;
}
.font-62 {
    font-size: 62px;
}
.font-400 {
    font-weight: 400;
}
.font-600 {
    font-weight: 600;
}
.white {
    color: white;
}
.flex {
    display: flex;
}
.grid {
    display: grid;
}
.grid-1-1 {
    grid-template-columns: 1fr 1fr;
}
.disabled {
    cursor: not-allowed;
}
.pointer {
    cursor: pointer;
}
.not-allow {
    cursor: not-allowed;
}
.ml-4 {
    margin-left: 4px;
}
.ml-8 {
    margin-left: 8px;
}
.ml-10 {
    margin-left: 10px;
}
.ml-12 {
    margin-left: 12px;
}
.ml-14 {
    margin-left: 14px;
}
.ml-16 {
    margin-left: 16px;
}
.mt-6 {
    margin-top: 6px;
}
.mt-12 {
    margin-top: 12px;
}
.mt-8 {
    margin-top: 8px;
}
.mt-14 {
    margin-top: 14px;
}
.mt-16 {
    margin-top: 16px;
}
.mt-22 {
    margin-top: 22px;
}
.mt-24 {
    margin-top: 24px;
}
.mb-14 {
    margin-bottom: 14px;
}
.mb-24 {
    margin-bottom: 24px;
}
.mr-4 {
    margin-right: 4px;
}
.mr-6 {
    margin-right: 6px;
}
.mr-8 {
    margin-right: 8px;
}
.pb-14 {
    padding-bottom: 14px;
}
.p-20 {
    padding: 20px;
}
.p-30 {
    padding: 30px;
}
.p-46 {
    padding: 46px;
}
.left-transform {
    transform: matrix(1, 0, -0.13, 0.99, 0, 0);
}
.radius-8 {
    border-radius: 8px;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.text-decoration-line {
    text-decoration-line: line-through;
}
.relative {
    position: relative;
}
.absolute {
    position: absolute;
}
.text-grey {
    color: rgba(255, 255, 255, 0.6);
}
.text-white {
    color: white;
}
.flex-1 {
    flex: 1;
}
.text-grey {
    color: rgba(255, 255, 255, 0.4);
}
