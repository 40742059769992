.generator-body {
    height: 96vh;
    min-height: 500px;
}
.p-l-r-16 {
    padding: 0 16px;
}
.p-l-r-48 {
    padding: 0 48px 0px;
}
.generator-left {
    width: 30%;
}
.generator-right {
    flex: 1;
}
.btn-default {
    background-color: #47CC09;
    border-color: #47CC09;
    padding: 13px 0;
    height: 48px;
    font-weight: 600;
    font-size: 16px;
    border-radius: 8px;
    text-align: center;
    position: absolute;
    bottom: 40px;
    transition: all .5s;
}
.btn-default-mobile {
    width: 90%;
}
.btn-default-pc {
    width: 27%;
}
.mask {
    position: absolute;
    top: 0;
    width: 100%;
    height: 48px;
    background-color: black;
    border-radius: 8px;
}
.masked {
    opacity: 0.6;
    transition: opacity 0.5s;
}
.un-masked {
    opacity: 0;
    transition: opacity 0.5s;
}
.can-hover:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #47cc09;
}
.generator-logo {
    position: relative;
    top: 2px;
    left: 4px;
}
#message {
    background-color: #212121;
    color: white;
    border: 1px solid #525268;
    width: 98%;
    margin-left: 20px;
    padding: 12px 8px;
}
.hint {
    color: white;
    font-size: 16px;
    margin-top: 8px;
}
.ant-select-selector {
    height: 48px !important;
    display: flex !important;
    align-items: center !important;
    font-size: 18px !important;
    background-color: #212121 !important;
    color: white !important;
    border: 1px solid #525268 !important;
}
.ant-select-selector:after {
    color: white !important;
}
.ant-select-dropdown {
    background-color: black !important;
}
.ant-select-item {
    color: white !important;
}
.ant-select-item:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #000000;
}
.ant-select-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: black;
}
.ant-select-selection-item {
    color: white !important;
    font-size: 14px;
}
.nextui-loading-label {
    color: white !important;
}
.right-body {
    position: relative;
    height: 82%;
    margin-left: 12px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #000000;
    border-radius: 16px;
    margin-top: 8px;
    overflow-y: scroll;
}
.loading-unit {
    position: absolute;
    left: 50%;
    top: 50%;
   	transform: translate(-50%, -50%);
}
.copy-btn {
    position: absolute;
    right: 10px;
    top: 10px;
    padding: 14px;
    background: rgba(255, 255, 255, 0.12);
    transition: all .5s;
    display: flex;
    align-items: center;
    display: none;
}

.gpt-side:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #000000;
}

.gpt-side:hover .copy-btn {
    display: flex;
}
.copy-btn:hover {
    background: rgba(255, 255, 255, 0.26);
}
.copy-hint {
    min-height: 40px;
    position: relative;
    display: table;
    text-align: center;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.24);
    position: absolute;
    color: white;
    width: 112px;
    bottom: 60px;
    right: -4px;
    padding-top: 5px;
    transition: all 2s;
    opacity: 0;
}
.show-hint {
    opacity: 1;
}
.copy-hint::before,
.copy-hint::after {
    position: absolute;
    content: "";
    display: inline-block;
    width: 0;
    height: 0;
    border: 8px solid transparent;
}
.copy-hint::before {
    bottom: -16px;
    right: 16px;
    border-top: 8px solid rgba(255, 255, 255, 0.24);
}
.copy-hint::after {
    bottom: -17px;
    z-index: -1;
}
.ant-modal .ant-modal-content {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #000000 !important;
}
.no-free {
    width: 160px;
}
.now-register {
    background-color: #47CC09;
    border-color: #47CC09;
    width: 262px;
    padding: 13px 0;
    margin: 0 auto;
    transition: all .5;
}
.now-register:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #47cc09;
}
.generator-right .ant-input::placeholder {
    color: rgba(255, 255, 255, 0.9);
}

.generator-left .create-chat {
    padding: 12px;
    border: 1px solid rgba(255, 255, 255, 0.36);
    border-radius: 8px;
    transition: all .5;
}

.generator-left .create-chat:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #000000;
}

.bottom-container {
    align-items: center;
    padding-left: 12px;
}
.gpt-container {
    width: 93%;
}

.history-item {
    padding: 10px 16px;
    margin: 12px 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #000000;
    border-radius: 8px;
}
.history-item span {
    overflow:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow:ellipsis;
    max-width: 90%;
}

.history-item:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #000000;
}
.footer {
    position: fixed;
    bottom: 0;
    height: 20px;
    width: 100%;
    background-color: #000;
    text-align: center;
    line-height: 50px;
}

@media screen and (max-device-width: 480px) {
    .ant-modal .ant-modal-content {
        background-color: rgb(59, 59, 59) !important;
    }
}