.payment-body .pay-card-mobile {
    width: 140px;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #000000;
    margin: 6px 5px;
}
.card-title-mobile {
    width: 100%;
    background: linear-gradient(317.53deg, #0FEAB4 4.33%, #B4FF14 104.13%);
    border-radius: 8px 8px 0px 0px;
    padding: 4px;
}