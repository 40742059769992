.register {
    padding: 14px 22px;
    display: inline-block;
    background: rgba(255, 255, 255, 0.12);
    transition: all .5s;
}
.register:hover {
    background: rgba(255, 255, 255, 0.26);
}
.invite {
    padding: 14px 22px;
    display: inline-block;
    transition: all .5s;
    background: url('../pages/public/bth-bg-hover.png') no-repeat;
    background-size: cover;
    color: black;
}
.invite:hover {
    color: rgba(0, 0, 0, 0.6);
    background: url('../pages/public/btn-bg.png') no-repeat;
    background-size: cover;
}
.upgrade {
    padding: 14px 22px;
    display: flex;
    align-items: center;
    color: black;
    background: linear-gradient(317.53deg, #0FEAB4 4.33%, #B4FF14 104.13%);
}
.login {
    opacity: 0.6;
    transition: all .5s;
    color: white;
}
.login:hover {
    opacity: 1;
}
.user-basic {
    display: inline-block;
    padding: 2px 18px 14px;
    transition: all .5s;
    border-radius: 8px;
    margin-left: 8px;
}

.user-basic:hover {
    background: rgba(255, 255, 255, 0.26);
}
.dropdown .ant-dropdown-menu {
    width: 198px;
    text-align: center;
    background-color: rgb(53, 52, 52);
}
.dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
    padding: 8px;
    color: white;
    font-weight: 600;
    text-align: left;
}
.dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
    background-color: rgba(255, 255, 255, 0.26);
}
.mobile-login {
    position: relative;
    right: 6px;
    top: -32px;
    z-index: 9;
}
.avatar-mobile {
    height: 0;
}
.rest-days:hover {
    background: rgba(255, 255, 255, 0.12) !important;
}