.payment-body {
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.payment-body .pay-card {
    width: 180px;
    border-radius: 8px;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #000000;
    margin: 0 20px;
}
.card-title {
    width: 100%;
    background: linear-gradient(317.53deg, #0FEAB4 4.33%, #B4FF14 104.13%);
    border-radius: 8px 8px 0px 0px;
    padding: 8px;
}
.card-body {
    border-radius: 8px;
}
.pay-price {
    background-image:-webkit-linear-gradient(317.53deg, #0FEAB4 4.33%, #B4FF14 104.13%); 
    -webkit-background-clip: text; 
    -webkit-text-fill-color: transparent;
    /* color: linear-gradient(317.53deg, #0FEAB4 4.33%, #B4FF14 104.13%); */
}
.back-btn {
    width: 120px;
    border-radius: 8px;
    margin: 20px auto 0;
    text-align: center;
    display: flex;
    justify-content: center;
}