.login-body {
    background-color: black;
    height: 100vh;
    justify-content: center;
    align-items: center;
}
.login-container img {
    width: 193px;
    margin-bottom: 48px;
}
.login-body .ant-input {
    width: 400px;
    border-radius: 8px;
    background: #212121;
    border: none;
    height: 48px;
    font-size: 16px;
    color: white;
}
.login-body .ant-input::placeholder {
    color: rgba(255, 255, 255, 0.6);
}
.send-code {
    position: absolute;
    right: 5px;
    height: 42px;
    padding: 10px 22px;
    top: 3px;
    color: rgba(255, 255, 255, 0.6);
}
.register-btn {
    position: inherit;
    margin-top: 48px;
    transition: all .5s;
    opacity: 0.8;
}
.register-btn:hover {
    opacity: 1;
}
@media screen and (max-device-width: 480px) {
    .login-container {
        width: 90%;
    }
    .login-body{
        align-items: flex-start;
        padding-top: 116px;
    }
    .login-body .ant-input {
        width: 100%;
    }
}