.personal-container {
    height: 88vh;
    padding: 0 24px;
}
.personal-container .left-side {
    width: 424px;
}
.personal-container .right-side {
    flex: 1;
    padding: 20px;
    height: 92%;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), #000000;
    border-radius: 16px;
    margin-top: 16px;
    margin-left: 16px;
    overflow-y: scroll;
}
.users {
    align-items: center;
}
.left-tab {
    padding: 16px;
    font-weight: 800;
    border-radius: 8px;
}
.actived {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), #000000;
}