.personal-header {
    padding: 16px;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
}
.invite-card {
    border-radius: 8px;
    max-height: 200px;
    background: linear-gradient(113.51deg, #2D3047 21.92%, #15151D 100.71%);
}
.card-left {
    flex: 1;
    padding: 20px;
}
.card-right {
    width: 35%;
}
.card-right img {
    height: 100%;
}
.pay-inside {
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    padding: 20px 0;
}
.invite-list {
    max-height: 40%;
    overflow-y: scroll;
}
.invite-list .list-item {
    align-items: center;
}