.body {
    background-color: black;
    height: 100%;
}
.header {
    grid-template-columns: 1fr 4fr;
    align-items: center;
}
.login {
    display: inline-block;
}
.container {
    grid-template-columns: 1fr 1fr;
    width: 100%;
}
.left {
    margin-top: 110px;
}
.title {
    line-height: 80px;
}

.left-text {
    margin-top: 22px;
    padding-right: 20px;
}
.free-use-btn {
    padding: 13px 0;
    color: black;
    border-radius: 8px;
    background-color: #47CC09;
    margin-top: 48px;
    transition: all .5s;
    width: 120px;
    text-align: center;
}
.free-btn-mobile {
    width: 100%;
    text-align: center;
}

.free-use-btn:hover {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #47cc09;
}

.img-container {
    width: 100%;
    text-align: center;
    height: 100%;
}

.img-container img {
    width: 80%;
    max-height: 100%;
}
.img-container-mobile {
    text-align: center;
}
.img-container-mobile img {
    width: 90%;
    max-height: 100%;
}

@media screen {
    
}